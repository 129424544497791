import Logo from "components/Logo";
import React, { useEffect, useState } from "react";

const PageLoading = () => {
  const [isMounted, setIsMounted] = useState(false);

  // Set is mounted
  useEffect(() => setIsMounted(true), []);

  // We only render this on the client, not server
  if (!isMounted) return null;

  return (
    <div className="PageLoading">
      <div className="PageLoading__content">
        <Logo isAnimating />
      </div>
    </div>
  );
};

export default PageLoading;
