import Video from 'components/Video';

const FeaturedVideo = ({
  heading,
  brightcoveVideoId,
  backgroundColor,
  textColor,
}) => (
  <div className="FeaturedVideo">
    <div className="FeaturedVideo__content">
      <div className="FeaturedVideo__panels">
        <div className="FeaturedVideo__panel">
          <div
            className="FeaturedVideo__header"
            style={{ backgroundColor, color: textColor }}
          >
            <h2 className="FeaturedVideo__heading">{heading}</h2>
          </div>
        </div>
        <div className="FeaturedVideo__panel">
          <div className="FeaturedVideo__video">
            <Video brightcoveVideoId={brightcoveVideoId} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

FeaturedVideo.defaultProps = {
  heading: '',
  brightcoveVideoId: null,
  backgroundColor: '#000000',
  textColor: '#ffffff',
};

export default FeaturedVideo;
