import clsx from "clsx";
import Ad from "./Ad";

const AdRosTop = ({ className = "" }) => {
  if (process.env.NEXT_PUBLIC_ADS_PROVIDER !== "hashtaglabs") return null;

  return (
    <Ad
      placementName="htlad-ros_top"
      className={clsx("border-b px-6 py-6 lg:px-12 lg:py-12", className)}
    />
  );
};

export default AdRosTop;
